
import { ISimpleDataItem } from '@/modules/system/types';
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IDamage, IManipulationType } from '../types';

@Component
export default class DamageDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly damage: IDamage | undefined;

  @Prop({ default: () => [] })
  readonly groups: Array<ISimpleDataItem> | undefined;

  item: IDamage | null = null;
  tab = 0;
  manipulationFilter = '';
  loading = false;
  linkedOnly = true;
  damageManipulations: Array<IManipulationType> = [];

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.damage);
      this.linkedOnly = true;
    }
  }

  @Watch('tab')
  onTabChange() {
    if (this.tab === 1) {
      this.refreshManipulations();
    }
  }

  get manipulations(): Array<IManipulationType> {
    return this.damageManipulations.filter(item => (!this.linkedOnly || item.linked) &&
      (
        this.manipulationFilter === '' ||
        item.name.toUpperCase().indexOf(this.manipulationFilter.toUpperCase()) > -1 ||
        item.code.toUpperCase().indexOf(this.manipulationFilter.toUpperCase()) > -1
      ));
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store(close: boolean) {
    for (let i = 0; i < this.groups!.length; i++) {
      if (this.groups![i].id === this.item!.group) {
        this.item!.groupName = this.groups![i].name;
        break;
      }
    }

    if (close) {
      this.$emit('store', this.item)
    } else {
      this.$emit('save', this.item)
    }
  }

  remove() {
    this.$emit('remove', this.item)
  }

  async refreshManipulations() {
    this.loading = true;
    this.damageManipulations = [];
    try {
      this.damageManipulations = await $app.get('/api/clinics/damages/' + this.item?.id + '/manipulations/all');
    } catch (err) {
      $app.pushError(err);
    }
    this.loading = false;
  }

  async changeManipulation(item: IManipulationType) {
    item.processing = true;
    try {
      if (item.linked) {
        this.damageManipulations = await $app.delete('/api/clinics/damages/' + this.item?.id + '/manipulations/' + item.code);
      } else {
        this.damageManipulations = await $app.post('/api/clinics/damages/' + this.item?.id + '/manipulations/' + item.code, {});
      }
    } catch (err) {
      $app.pushError(err);
    }
    item.processing = false;
  }

  setCuringManipulation(item: IManipulationType) {
    if (this.item) {
      let curings: Array<string> = this.item.curing ? this.item.curing.split(';') : []

      if (curings.some(c => c === item.code)) {
        curings = curings.filter(c => c !== item.code)
      } else {
        curings.push(item.code)
      }

      this.item.curing = curings.join(';')
      this.store(false);
    }
  }
}
